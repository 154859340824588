<template>
  <DashboardBackgroundDecoration />
  <div class="grow flex flex-col bg-gray-700/70">
    <div class="sticky top-0 w-full py-1 px-4 bg-warning z-20">
      <div class="relative w-full flex justify-center items-center px-10">
        <NuxtLink
          class="absolute z-[1] left-0 flex items-center gap-1 text-sm"
          :to="localeRoute('events-eventId')">
          <PhArrowLeft :size="18" />Back</NuxtLink
        >
        <span class="text-center">
          <strong>Instabooth Branding Studio</strong> 🦋 Preview and brand the
          Instabooth App for your guests.
        </span>
      </div>
    </div>
    <div class="container mx-auto p-6 pt-8">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PhArrowLeft, PhCaretLeft } from "@phosphor-icons/vue";

  const localeRoute = useLocaleRoute();
</script>

<style>
  body {
    padding-top: env(safe-area-inset-top);
  }
</style>
